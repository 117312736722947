<style scoped>
	div,input,textarea{box-sizing: border-box;}
	.page_box_add_community{display: flex;flex-direction: column;overflow: hidden;}
	.page_box_body{overflow: auto;flex: 1;}
	.page_box_footer{padding: 10px 10px 0 10px;text-align: right;}
	/* 表单 */
	
	.z_form_item_row{padding: 10px 0;}
	.z_form_item{flex: 1;display: flex;flex-direction: row;align-items:center ;}
	.z_form_item_label{width: 100px;text-align: right;padding-right: 10px;}
	.z_form_item_value{flex: 1;}
	.z_form input{height: 30px;width: 100%;}
	.z_form textarea{height: 80px;width: 100%;padding: 5px;}
</style>
<style>
	.z_form .el-form-item{margin-bottom: 0;}
	.z_form .el-form-item__content{margin-left: 0!important;}
</style>
<template>
	<div class="page_box_add_community">
		<div class="page_box_header"></div>
		<div class="page_box_body">
			<div class="z_form">
				<el-form label-width="80px" :model="form" :rules="rules" ref="form">
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="8">
							<el-form-item prop="name">
								<div class="z_form_item">
									<div class="z_form_item_label">小区名称</div>
									<div class="z_form_item_value">
										<el-input placeholder="必填" type="text" v-model="form.name"></el-input>
									</div>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="16">
							<div class="z_form_item">
								<div class="z_form_item_label">小区位置</div>
								<div class="z_form_item_value">
									<el-input v-model="form.address"></el-input>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">占地面积/m<sup>2</sup></div>
								<div class="z_form_item_value">
									<el-input v-model="form.zd_area"></el-input>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">建筑面积/m<sup>2</sup></div>
								<div class="z_form_item_value">
									<el-input v-model="form.jz_area"></el-input>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">使用面积/m<sup>2</sup></div>
								<div class="z_form_item_value">
									<el-input v-model="form.sy_area"></el-input>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">绿化面积/m<sup>2</sup></div>
								<div class="z_form_item_value">
									<el-input v-model="form.lh_area"></el-input>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">绿化率%</div>
								<div class="z_form_item_value">
									<el-input v-model="form.lh_lv"></el-input>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">楼宇数</div>
								<div class="z_form_item_value">
									<el-input v-model="form.build_num"></el-input>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">单元数</div>
								<div class="z_form_item_value">
									<el-input v-model="form.unit_num"></el-input>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">竣工时间</div>
								<div class="z_form_item_value">
									<el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="form.jg_time" style="width: 100%;"></el-date-picker>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">入住时间</div>
								<div class="z_form_item_value">
									<el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="form.rz_time" style="width: 100%;"></el-date-picker>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="8">
							<div class="z_form_item">
								<div class="z_form_item_label">值班电话</div>
								<div class="z_form_item_value">
									<el-input v-model="form.contact_phone"></el-input>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="24">
							<div class="z_form_item">
								<div class="z_form_item_label">备注</div>
								<div class="z_form_item_value">
									<el-input type="textarea" v-model="form.remark"></el-input>
									<!-- <textarea rows="3" cols="20"></textarea> -->
								</div>
							</div>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</div>
		<div class="page_box_footer">
			<el-button @click="close()">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="onSubmit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		data() {
			return {
				form: {
					name: '',
					address: '',
					zd_area: '',
					jz_area: '',
					sy_area: '',
					lh_area: '',
					lh_lv: '',
					build_num: '',
					unit_num: '',
					jg_time: '',
					rz_time: '',
					contact_phone:'',
					remark: ''
				},
				rules: null,
				btnSaveLoading:false,
			}
		},
		created: function() {
			_this = this;
		},
		methods: {
			// _** 提交保存
			onSubmit() {
				_this.btnSaveLoading = true;
				var params = _this.form;
				if (params.name == '') {
					_this.$confirm('小区名必填', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {

					}).catch(() => {

					});
					_this.btnSaveLoading = false;
					return;
				}
				_this._postApi('/wy/residence/create', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent','');
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			},
			
			/**   
			* 关闭
			* author:郑凯 
			* create_time:2021-3-11 15:27:08 
			*/
		   close(){
			   _this.$emit('childrenEvent','');
		   }
		}
	}
</script>
