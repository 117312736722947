<style scoped>
	.template {height: 100%;width: 100%;overflow: hidden;position: relative;}
	.page_box {height: 100%;width: 100%;overflow: hidden;display: flex;flex-direction: column;}
	.header_box{padding:10px 5px 5px 10px;}
	.main_box {flex: 1;overflow: hidden;}
	.main_box_content{height: 100%;overflow-y: auto;padding: 0 10px 0 10px;}
	.footer_box{text-align: right;padding: 10px 30px 10px 10px;}
	/* 弹窗 */
	.el-dialog__wrapper{position: absolute;left: 0;top: 0;display: flex;align-items: center;overflow: hidden;}
	.el-dialog.my_dialog{margin-top: 0;}
</style>
<style>
	table{box-sizing: border-box;}
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	.el-table__body tr.current-row>td{
	  background-color: #67CD80 !important;
	  color: #fff;
	}
	.mb .el-breadcrumb__inner{font-weight: bold!important;font-size: 1.1rem;}
</style>
<template>
	<div class="template">
		<div class="page_box">
			<div class="header_box">
				<el-button type="warning" size="small" icon="el-icon-circle-plus-outline" @click="addCommunity">添加小区</el-button>
				<el-button type="warning" size="small" @click="editCommunity">编辑<i class="el-icon-edit"></i></el-button>
				<el-button type="warning" size="small" @click="deleteCommunity">删除<i class="el-icon-delete"></i></el-button>
				<el-button type="danger" size="small" @click="api_getCommunityList">刷新<i class="el-icon-refresh-right"></i></el-button>
			</div>
			<div class="main_box" id="main_box">
				<div class="main_box_content" v-loading="tableLoading">
					<el-table :data="communityList"  highlight-current-row  @row-click="rowClick" style="width: 100%" :height="tableHeight" border>
						<el-table-column prop="name" label="小区名" width=""></el-table-column>
						<el-table-column prop="address" label="地理位置" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="zd_area" label="占地面积/m2" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="jz_area" label="建筑面积/m2" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="sy_area" label="使用面积/m2" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="lh_area" label="绿化面积/m2" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="lh_lv" label="绿化率%" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="build_num" label="楼宇数" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="unit_num" label="单元数" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="jg_time" label="竣工时间" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="rz_time" label="入住时间" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="contact_phone" label="值班电话" width="" :formatter="formatterCellval"></el-table-column>
						<!-- <el-table-column fixed="right" label="操作" width="160">
							<template slot-scope="scope">
								<el-row style="padding-right: 10px;">
									<el-button @click="editCommunity(scope.row)" type="text" size="mini">编辑</el-button>
									<el-button @click="deleteCommunity(scope.row)" type="danger" size="mini">删除</el-button>
								</el-row>
							</template>
						</el-table-column> -->
					</el-table>
				</div>
			</div>
			<div class="footer_box">
				<!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNumber"
				 :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="400">
				</el-pagination> -->
			</div>
		</div>
		<!-- 添加小区弹窗 -->
		<el-dialog custom-class="my_dialog" width="60%" top="none" title="添加小区" :visible.sync="dialogAddCommunity"
		 destroy-on-close>
			<!-- <div class="dialog_box"> -->
			<add_community v-on:childrenEvent="handlerChildrenEvent"></add_community>
			<!-- </div> -->
		</el-dialog>
		
		<!-- 添加小区弹窗 -->
		<el-dialog custom-class="my_dialog" width="60%" top="none" title="编辑小区" :visible.sync="dialogEditCommunity"
		 destroy-on-close>
			<edit_community v-if="dialogEditCommunity" :id="selectCommunityId" v-on:childrenEvent="handlerChildrenEventByEditCommiunity"></edit_community>
		</el-dialog>
	</div>
</template>

<script>
	import add_community from './add_community.vue'
	import edit_community from './edit_community.vue'
	var _this;
	export default {
		components: {
			add_community,edit_community
		},
		data() {
			return {
				tableHeight: 0,
				tableLoading: true,
				selectTableRow:null,
				pageNumber: 1,
				communityList: [],
				dialogAddCommunity: false, //添加小区弹窗
				dialogEditCommunity:false,//编辑小区弹窗
				selectCommunityId:0
			}
		},
		created: function() {
			_this = this;
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("main_box").offsetHeight;
				_this.api_getCommunityList();
			})
		},
		methods: {
			/* * 刷新 */
			thisReload() {
				_this.selectTableRow = null;
				_this.tableHeight = document.getElementById("main_box").offsetHeight;
				_this.api_getCommunityList();
			},
			/* * 获取小区列表 */
			api_getCommunityList() {
				_this.tableLoading = true;
				_this._getApi('/wy/account/getResidenceListByAdmin', {})
					.then((res) => {
						if (res.code == 1) {
							_this.communityList = res.data;
							_this.tableLoading = false;
						} else {

						}
					})
					.catch((err) => {})
			},

			// _ ** 表格占位
			formatterCellval(row, column, cellValue, index) {
				//console.log(Boolean(cellValue), cellValue);
				if (!cellValue) {
					return "--";
				} else {
					return cellValue;
				}
			},
			/* * 选择某行 */
			rowClick(row, column, event) {
				_this.selectTableRow = row;
			},

			// _ ** 添加小区
			addCommunity() {
				_this.dialogAddCommunity = true;
			},
			
			// _ ** 编辑小区
			editCommunity(object) {
				var baseData = _this.selectTableRow;
				if (!baseData) {
					_this.$alert('请先选择小区进行修改,点击小区即可选中', '提示', {
						confirmButtonText: '确定',
						callback: action => {}
					});
					return;
				}
				_this.selectCommunityId = baseData.id;
				_this.dialogEditCommunity = true;
			},

			// _ ** 删除某个小区
			deleteCommunity(object) {
				var baseData = _this.selectTableRow;
				if (!baseData) {
					_this.$alert('请先选择小区进行删除,点击小区即可选中', '提示', {
						confirmButtonText: '确定',
						callback: action => {}
					});
					return;
				}
				var h = _this.$createElement;
				_this.$msgbox({
					title: '不可逆操作',
					message: h('p', null, [
						h('span', null, '确定要删除 '),
						h('span', {
							style: 'color: teal'
						}, baseData.name + ' 吗？')
					]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							_this._postApi('/wy/residence/delete', {
								id: baseData.id
							}).then((res) => {
								if (res.code == 1) {
				
									_this.thisReload();
									_this.selectTableRow = null;
									_this.$message({
										message: '删除成功！',
										type: 'success',
										duration: 1000,
										onClose: function() {
											done();
											instance.confirmButtonLoading = false;
										}
									});
								} else {
									_this.$message({
										message: res.msg,
										type: 'error',
										onClose: function() {
											done();
											instance.confirmButtonLoading = false;
										}
									});
								}
							}).catch((err) => {});
						} else {
							done();
						}
					}
				}).then(action => {
				
				}).catch(() => {});
			},

			// _ ** 分页
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			},

			// _ ** 处理添加小区子组件事件
			handlerChildrenEvent(e) {
				_this.thisReload();
				_this.dialogAddCommunity = false;
			},
			// _ ** 处理编辑小区子组件事件
			handlerChildrenEventByEditCommiunity(e) {
				_this.thisReload();
				_this.dialogEditCommunity = false;
			}
		}
	}
</script>
